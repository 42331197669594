.button{
  padding: 7px 24px;
  width: 100%;
  background: #D90429;
  border-radius: 6px;
  border: 2px solid #D90429;
  transition: all ease 0.4s;
  p{
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
  }
  &:hover{
    box-shadow: inset 0 5px 30px 0 #fff;
    transition: all ease 0.4s;
    background: #D90429;
  }
}